import "../styles/pricing/one.scss";

const pricingFetures = [
  "200% ROI",
  "$10,000 Limit",
  "24/7 Support",
  "Plan runs for five days",
  "Personal account manager",
];
const pricingFetures2 = [
  "300% ROI",
  "$99,500 Limit",
  "24/7 Support",
  "Plan runs for six days",
  "Personal account manager",
];

const pricing = [
  {
    name: "Lite",
    min: "$5,000",
    icon: "/icons/lite.svg",
    features: [
      "200% ROI",
      "$10,000 Limit",
      "24/7 Support",
      "Plan runs for five days",
      "Personal account manager",
    ],
    // ROI: "200% ROI",
    // Limit: "$10,000 Limit",
    // Support: "24/7 Support",
    // Time: "Plan runs for five days",
    // Extra: "Personal account manager",
  },
  {
    name: "Starter",
    min: "$11,000",
    icon: "/icons/starter.svg",
    features: [
      "300% ROI",
      "$99,500 Limit",
      "24/7 Support",
      "Plan runs for six days",
      "Personal account manager",
    ],
    // ROI: "300% ROI",
    // Limit: "$99,500 Limit",
    // Support: "24/7 Support",
    // Time: "Plan runs for six days",
    // Extra: "Personal account manager",
  },
];

const PricingOne = () => {
  return (
    <div className="pricing__one__container">
      <div className="pricing__one__wrapper">
        <div className="pricing__one__text">
          <p className="pricing__one__section__label">Pricing</p>
          <h1 className="pricing__one__section__title">
            Pricing plans that scale
          </h1>
          <p className="pricing__one__section__supporting__text">
            Simple, transparent pricing that grows with you. Try any plan free
            for 30 days.
          </p>
        </div>

        <div className="pricing__one__cards">
          {pricing.map((card) => (
            <div className="pricing__one__card">
              <div className="pricing__one__card__top">
                <figure>
                  <img src={card.icon} alt="" />
                </figure>

                <span>
                  <p className="pricing__one__card__name">{card.name}</p>
                  <h1 className="pricing__one__card__price">{card.min}</h1>
                </span>
              </div>

              <div className="pricing__one__card__mid">
                <ul className="pricing__one__feature__list">
                  {card.features?.map((feature) => (
                    <li key={feature} className="pricing__one__feature__item">
                      <img src="icons/purple-check.svg" alt="check" />
                      <p>{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <a href="https://primefintech.net">
                <div className="pricing__one__card__bottom">
                  <button className="pricing__one__card__button">
                    Get started
                  </button>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingOne;
