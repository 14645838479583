import { Link } from "react-router-dom";
import "../styles/cta/one.scss";
const CTAOne = () => {
  return (
    <div className="cta__one__container">
      <div className="cta__one__wrapper">
        <h1 className="cta__one__text">
          Join the team of our experienced traders
        </h1>
        <span className="cta__one__buttons">
          <button className="cta__one__button__white">
            <Link to="/about" className="cta__one__button__white">
              Learn more
            </Link>
          </button>

          <button className="cta__one__button__blue">
            <a href="https://primefintech.net">Get Started</a>
          </button>
        </span>
      </div>
    </div>
  );
};

export default CTAOne;
